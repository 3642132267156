<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'

const colorMode = useColorMode()
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="outline" size="icon">
        <iconify-icon icon="lucide:moon" class="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
        <iconify-icon icon="lucide:sun" class="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
        <span class="sr-only">Toggle theme</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuItem @click="colorMode = 'light'">
        Light
      </DropdownMenuItem>
      <DropdownMenuItem @click="colorMode = 'dark'">
        Dark
      </DropdownMenuItem>
      <DropdownMenuItem @click="colorMode = 'auto'">
        System
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
