<script lang="ts" setup>
import { differenceInDays } from 'date-fns'

const config = useRuntimeConfig()

const { data } = useAuth()

const remainingTrialDays = computed(() => {
  if (!data.value?.user?.trialEnd) {
    return null
  }
  const trialEnd = new Date(data.value.user.trialEnd)
  return differenceInDays(trialEnd, new Date()) < 0 ? 0 : differenceInDays(trialEnd, new Date())
})
const isInBeta = computed(() => {
  return config.public.in_beta
})
</script>

<template>
  <div>
    <div v-if="remainingTrialDays && !isInBeta" class="bg-primary text-white flex justify-center items-center text-center px-4">
      <span class="text-lg font-semibold text-center">
        You have {{ remainingTrialDays }} days left in your trial. <NuxtLink to="/dashboard/billing" class="underline">Upgrade now</NuxtLink>
      </span>
    </div>
    <div v-if="isInBeta" class="bg-primary text-white flex justify-center items-center px-4 text-center">
      <span class="text-lg font-semibold text-center">
        Featrack is currently at it's very early stages. Some features are still in development.
      </span>
    </div>
  </div>
</template>
